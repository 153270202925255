import React, { Component } from 'react';
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import config from "data/SiteConfig";
import Layout from 'layout';
import TopBanner from 'partials/topBanner';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as weaponsActions from 'redux/actions/weapons';

class WeaponLayout extends Component {

    componentDidMount() {
        const { pageContext, weaponsActions } = this.props;
        weaponsActions.put_data_weapons('tab', pageContext.pages);
    }

    populateList = (items) => {
        return items.data.map((data,index) => 
            <div key={index} className="weapon-list__list">
                <div className="left">
                    <div className="name-cont">
                        <div className="name">
                            <img src={require('assets/img/senjata/Icon_CODM.png')} alt=""/>
                            <span>{data.name}</span>
                        </div>
                        <div className="line"></div>
                    </div>
                    <div className="image-cont">
                        <img src={data.image} alt=""/>
                    </div>
                </div>
                <div className="right">
                    <div className="scale-container">
                        {this.populateScale([data.damage, data.fire_rate, data.accuracy, data.mobility, data.range])}
                    </div>
                </div>
            </div>
        )
    }

    populateScale = (value) => {
        const titles = ["Damage", "Fire Rate", "Accuracy", "Mobility", "Range"];
        return titles.map((data, index) => 
            <div key={index} className="scale-cont">
                <div className="text-cont">
                    <span>{data}</span>
                    <span>{value[index]}</span>
                </div>
                <div className="progress-bar">
                    <div className="progress" style={{width: `${value[index]}%`}}></div>
                </div>
            </div>
        )
    }

    handleChangeTab = (e, data) => {
        e.preventDefault();
        const {weaponsActions} = this.props;
        weaponsActions.put_data_weapons('tab', data);
		if(typeof window !== 'undefined') {
            window.history.pushState({}, document.title, `${window.location.origin}/guide/weapons/${data}`);
        }
    }

    generateTab = () => {
        const { weapons } = this.props.data;
        const { tab } = this.props.weapons;
        return weapons.edges.map((data,index) => 
            <li key={index} className={data.node.slug === tab ? `active`: ``} onClick={(e) => this.handleChangeTab(e, data.node.slug)}>
                {data.node.name}
            </li>
        )
    }

    generateTabContent = () => {
        const { weapons } = this.props.data;
        const { tab } = this.props.weapons;
        return weapons.edges.map((data,index) => 
            <div key={index} className={`tab-container__content animated fadeIn fast ${(data.node.slug === tab) && 'active'}`}>
                <span className="title">{data.node.name}</span>
                <p className="description">
                    {data.node.description}
                </p>
                <div className="weapon-list">
                    {this.populateList(data.node.items)}
                </div>
            </div>
        )
    }

    render() {
        return (
            <Layout>
                <Helmet title={"Weapons - " + config.siteTitle} />
                <div id="WeaponLayout" className="content-container">
                    <TopBanner 
                        image={require('assets/img/senjata/BG_Senjata.jpg')} 
                        firstString="Pilihan"
                        secondString="Senjata"
                        subtitle="Pilih Senjata sesuai"
                        description="dengan preferensi kamu"
                    />
                    <div className="content">
                        <div className="content__title">Senjata</div>
                        <div className="content__list">
                            <ul className="tabs__title">
                                {this.generateTab()}
                            </ul>
                            <div className="tabs__content">
                                {this.generateTabContent()}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}


function mapStateToProps(state) {
	return { weapons: state.weapons }
}

function mapDispatchToProps(dispatch) {
	return {
		weaponsActions: bindActionCreators(weaponsActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(WeaponLayout); 

/* eslint no-undef: "off" */
export const pageQuery = graphql`
{
    weapons: allDirectusWeapon {
      edges {
        node {
          name
          slug
          description
          items {
            data {
              name
              image
              description
              damage
              accuracy
              fire_rate
              mobility
              range
            }
          }
        }
      }
    }
  }
  
`;